import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { isValidHttpUrl } from "../../../utils/common";
import { withStyles } from 'tss-react/mui';

const useStyles = {
  formControl: {
    minWidth: 212,
    maxWidth: "fit-content",
    display: "flex !important",
    "& .MuiSelect-outlined": {
      display: "flex !important",
      padding: 5,
      paddingRight: 25,
    },
  },
  selectEmpty: {
    marginTop: "2",
    display: "flex",
  },
};

const SwitchOptionsDropdown = ({
  value,
  onChange,
  classes,
  switchOptions = [],
}) => {
  let selectedValue = switchOptions.find((el) => el.selected === true)
    ? switchOptions.find((el) => el.selected === true).value
    : value;
  selectedValue = value ? value : selectedValue;
  const [option, setOption] = useState(
    selectedValue
      ? selectedValue
      : switchOptions[switchOptions.length - 1] && switchOptions[switchOptions.length - 1].value
  );
  const handleChange = (e, value) => {
    let newOption = value.props.value || option;
    setOption(newOption);
    onChange(newOption);
  };

  if (!option) return null;

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      onChange={handleChange}
      fullWidth
    >
      <Select variant="standard" value={selectedValue} onChange={handleChange}>
        {switchOptions.map((item) => {
          return (
            <MenuItem value={item.value} key={item.value} alt={item.label} selected={item.selected}>
              <div className="flex flex-row">
                <Avatar
                  alt={item.label}
                  src={
                    isValidHttpUrl(item.logo)
                      ? item.logo
                      : "https://app.datagram.ai" + item.logo
                  }
                />
                <ListItemText  disableTypography classes={{ primary:"justify-items-center max-w-sm"}} primary={<div className="max-w-sm text-wrap" style={{textWrap: "wrap" }} >{item.label}</div>} />
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default withStyles(SwitchOptionsDropdown, useStyles);
